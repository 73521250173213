import {createSlice} from "@reduxjs/toolkit";

export const platformSlice = createSlice({
	name: 'platformSlice',
	initialState: {
		saved: [],
		types: [],
		locale: {}
	},
	reducers: {
		setPlatformTypes: (state, action) => {
			state.types = action.payload
		},
		setPlatformsLocales: (state, action) => {
			state.locale = action.payload
		},
		setSavedPlatforms: (state, action) => {
			state.saved = action.payload
		}
	}
})

export const {
	setSavedPlatforms,
	setPlatformsLocales,
	setPlatformTypes
} = platformSlice.actions

export default platformSlice.reducer