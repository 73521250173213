import React, {useEffect, useRef, useState} from "react";
import {getPlatformsByIDs} from "../../requests/platforms";
import Skeleton from "react-loading-skeleton";
import PlatformsModalTable from "./PlatformsModalTable";

export default function PlatformFromSaved({name, platforms, crossClick}){
	const [fetching, setFetching] = useState(false)
	const [fetchedPlatforms, setFetchedPlatforms] = useState([])
	const modalRef = useRef();

	useEffect(() => {// получаем по списку информацию об источниках
		if(platforms){
			setFetching(true)
			getPlatformsByIDs(platforms)
				.then((resp) => {
					if(resp.data && resp.data.paginationPlatform && resp.data.paginationPlatform.listPlatform)
						setFetchedPlatforms(resp.data.paginationPlatform.listPlatform)
				})
				.catch((resp) => {
					console.error("getPlatformsByIDsFail", resp)
				})
				.finally(() => {setFetching(false)})
		}
	}, [platforms]);

	// выводим выбранный список источников с таблицей
	return <div>
		<h1 className="title-big c-main-dark" style={{marginBottom: "1.75rem"}}>Выбор источников:</h1>
		<div>
			<div className="title c-main-dark">Выбрать из списка сохраненных источников:</div>
		</div>
		{name &&
			<div>
				<div className="selected_platform_list">
					<span>{name}</span>
					<div className="cross" onClick={() => {crossClick()}}></div>
				</div>
			</div>
		}
		<div className="text" style={{marginTop: "1.75rem"}}>
			<p>Количество источников в выбранном списке: {platforms.length}</p>
			{fetching
				? <p className="text--blue"><Skeleton width={'17rem'}/></p>
				: <button className="text--blue text_button" onClick={()=>modalRef.current.classList.add('active')}>
					Посмотреть выбранные источники
				</button>
			}
		</div>
		{!fetching &&
			<PlatformsModalTable modalRef={modalRef} platforms={fetchedPlatforms} fromStream={true} />
		}

	</div>
}