export const COMPOSITE_VALUE_INFO = `
	fragment CompositeValueInfo on CompositeValue {
        list: listValue {
            propertyValueType {
                ...CompositePropertyValueTypeInfo
                __typename
            }
            value {
                ...PartValueInfo
                __typename
            }
            __typename
        }
        __typename
    }`

export const GEO_POINT_INFO = `
	fragment GeoPointInfo on GeoPointValue {
        point {
            latitude
            longitude
            __typename
        }
        text: name
        __typename
    }`

export const DATE_TIME_FULL = `
	fragment DateTimeFull on DateTimeValue {
        date {
            year
            month
            day
            __typename
        }
        time {
            hour
            minute
            second
            __typename
        }
        __typename
    }`

export const DOUBLE_VALUE_INFO = `
	fragment DoubleValueInfo on DoubleValue {
        double: value
        __typename
    }`

export const INT_VALUE_INFO = `
	fragment IntValueInfo on IntValue {
        number: value
        __typename
    }`

export const PART_VALUE_INFO = `
	fragment PartValueInfo on Value {
        __typename
        ...IntValueInfo
        ...DoubleValueInfo
        ...DateTimeFull
        ...GeoPointInfo
        ... on StringLocaleValue {
            value
            locale
            __typename
        }
        ... on StringValue {
            value
            __typename
        }
        ... on LinkValue {
            link
            __typename
        }
    }
    ${INT_VALUE_INFO}
    ${DOUBLE_VALUE_INFO}
    ${GEO_POINT_INFO}
    `

export const PROPERTY_VALUE_INFO = `
    fragment PropertyValueInfo on AnyValue {
        __typename
        ...PartValueInfo
        ...CompositeValueInfo
    }
    ${PART_VALUE_INFO}
    ${COMPOSITE_VALUE_INFO}
    `

export const REL_EXT_MODEL_INFO = `
    fragment RelExtModelInfo on RelExtModel {
        sourceAnnotationType
        targetAnnotationType
        relationType
        invertDirection
        __typename
    }`

export const USER_PREVIEW = `
    fragment UserPreview on User {
        id
        login
        name
        __typename
    }`

export const RECORD_INFO = `
    fragment RecordInfo on RecordInterface {
        systemRegistrationDate
        systemUpdateDate
        creator {
            ...UserPreview
            __typename
        }
        lastUpdater {
            ...UserPreview
            __typename
        }
        __typename
    }
    
    ${USER_PREVIEW}
    `

export const COMPOSITE_PROP_VALUE_TEMPLATE_INLINE =`
    fragment CompositePropertyValueTemplateInline on CompositePropertyValueTemplate {
        id
        name
        __typename
    }`

export const CONCEPT_PROP_VALUE_TYPE_INLINE = `
    fragment ConceptPropertyValueTypeInline on ConceptPropertyValueType {
        id
        name
        valueType
        valueRestriction
        __typename
    }`

export const COMPOSITE_PROPERTY_VALUE_TYPE_INFO = `
    fragment CompositePropertyValueTypeInfo on CompositePropertyValueType {
        id
        name
        isRequired
        view
        valueType {
            ...ConceptPropertyValueTypeInline
            __typename
        }
        __typename
    }
    `

export const COMPOSITE_PROP_VALUE_TEMPLATE_INFO = `
    fragment CompositePropertyValueTemplateInfo on CompositePropertyValueTemplate {
        id
        componentValueTypes {
            ...CompositePropertyValueTypeInfo
            __typename
        }
        ...CompositePropertyValueTemplateInline
        ...RecordInfo
        __typename
    }
    ${COMPOSITE_PROPERTY_VALUE_TYPE_INFO}
    ${COMPOSITE_PROP_VALUE_TEMPLATE_INLINE}
    ${RECORD_INFO}
    `

export const CONCEPT_PROP_VALUE_TYPE_INFO = `
    fragment ConceptPropertyTypeInfo on ConceptPropertyType {
        id
        name
        valueType {
            ...ConceptPropertyValueTypeInline
            ...CompositePropertyValueTemplateInfo
            __typename
        }
        notifyOnUpdate
        pretrainedRelExtModels {
            ...RelExtModelInfo
            __typename
        }
        __typename
    }
    ${COMPOSITE_PROP_VALUE_TEMPLATE_INFO}
    `

export const ACCESS_LEVEL_INFO =`
    fragment AccessLevelInfo on AccessLevel {
        id
        name
        order
        __typename
    }`

export const CONCEPT_PROPERTY_INLINE =`
    fragment ConceptPropertyInline on ConceptProperty {
        id
        isMain
        accessLevel {
            ...AccessLevelInfo
            __typename
        }
        propertyType {
            ...ConceptPropertyTypeInfo
            __typename
        }
        value {
            ...PropertyValueInfo
            __typename
        }
        __typename
    }
    ${ACCESS_LEVEL_INFO}
    ${CONCEPT_PROP_VALUE_TYPE_INFO}
    `

export const CONCEPT_PROP_INFO = `
    fragment ConceptPropertyInfo on ConceptProperty {
        id
        ...ConceptPropertyInline
        startDate {
            ...DateTimeFull
            __typename
        }
        endDate {
            ...DateTimeFull
            __typename
        }
        notes
        paginationDocument {
            total
            __typename
        }
        __typename
    }
    ${CONCEPT_PROPERTY_INLINE}
    `

export const METRIC_INFO = `
    fragment MetricInfo on ConceptStatistics{
        countProperties
        countObjects
        countEvents
        countDocumentMentions
        countResearchMaps
        countConcepts
    }`

export const CONCEPT_SHORT_INFO = `
    fragment ConceptShortInfo on Concept{
        id
        name
        image {
          url
          thumbnail
        }
        metric{
            ...MetricInfo
        }
        conceptType{
            id
            name
        }
    }
    
    ${METRIC_INFO}`

export const DOC_METRIC_INFO = `
    fragment DocMetricInfo on Metrics{
      countObjects
      countEvents
      countEntities
      countNamedEntities
      countDisambiguatedEntities
      countLinks
      countResearchMaps
      countChildDocs
      countTasks
      countConcepts
    }`

export const TEXT_META = `
    fragment TextMeta on ParagraphMetadata{
      pageId
      lineId
      originalText
      hidden
      paragraphType
    }
`
// export const DOC_LANGUAGE = `
//     fragment DocLanguage on FlatDocumentStructure{
//         language{
//             name
//             russianName
//             englishName
//             __typename
//         }
//     }
// `
export const DOC_LANGUAGE = `
    fragment DocLanguage on FlatDocumentStructure{
        language{
            id
        }
    }
`

export const DOC_TEXT_FRAGMENT = `
    fragment DocTextFragment on FlatDocumentStructure{
      id
      documentId
      nodeId
      hierarchyLevel
      translatedText
      metadata{
        ...TextMeta
      }
      ...DocLanguage
      text
      __typename
    }
    ${TEXT_META}
    ${DOC_LANGUAGE}
`

export const DOCUMENT_INFO = `
    fragment DocInfo on Document{
        id
        title
        preview
        externalUrl
        internalUrl
        documentType
        trustLevel
        publicationDate
        systemRegistrationDate
        hasText
        text{
            ...DocTextFragment
        }
        accessLevel{
            id
            name
            order
        }
        metric{
            ...DocMetricInfo
        }
    }
    ${DOC_TEXT_FRAGMENT}
`

export const DOCUMENT_META_INFO = `
    fragment DocumentMetaInfo on Document{
        metadata{
            platform{
              id
              name
              platformType
              url
              country
              language
            }
            source
            account{
              id
              name
              url
              country
            }
        }
    }
`

export const DOCUMENT_CONCEPTS_INFO = `
    fragment DocumentConceptsInfo on Document {
      ...DocInfo
      ...DocumentMetaInfo
      paginationConceptFact(limit: 100){
        total
        listConceptFact{
          concept{
            ...ConceptShortInfo
          }
        }
      }
    }
    ${DOCUMENT_INFO}
    ${DOC_METRIC_INFO}
    ${DOCUMENT_META_INFO}
    `

export const STORY_PREVIEW = `
    fragment StoryPreview on Story{
      id
      title
      preview
    }
`

export const PLATFORM_IN_LINE = `
    fragment PlatformInline on Platform {
	  id
	  image {
	    url
	    thumbnail
	    __typename
	  }
	  name
	  url
	  platformType
	  country
	  language
	  __typename
	}
`

export const PLATFORM_PARAMS= `
    fragment PlatformParams on Platform {
	  id
	  params {
	    value
	    key
	    __typename
	  }
	  __typename
	}
`

export const PLATFORM_INFO= `
    fragment PlatformInfo on Platform {
      ...PlatformInline
      ...PlatformParams
      ...RecordInfo
      markers
      metric {
        countAccount
        countDoc
        __typename
      }
      period {
        startDate: start {
          ...DateTimeFull
          __typename
        }
        endDate: end {
          ...DateTimeFull
          __typename
        }
        __typename
      }
      __typename
    }
    
    ${PLATFORM_IN_LINE}
    ${PLATFORM_PARAMS}
    ${RECORD_INFO}
    ${DATE_TIME_FULL}
`
