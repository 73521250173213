import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from "react-redux";
import {Link, useSearchParams} from "react-router-dom";
import Tooltip from "../../components/ui/Tooltip";
import PlatformsPage from "../Platforms/PlatformsPage";
import PlatformFromSaved from "../../components/platforms/PlatformFromSaved";
import {APIClient} from "../../ApiEndpoints";
import {DOCUMENT_AND_STORIES_STAT} from "../../graphql/statisticQueries";
import DatePicker, {Calendar, utils} from "@hassanmojab/react-modern-calendar-datepicker";
import {CALENDAR_LOCALE} from "../../Consts";
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';


const QueryStringConstructor = React.lazy(() => import("../../components/stream/QueryStringConstructor"))

const ShowBtn = ({phrases, sources, concepts, conceptLogic, selectedDay}) => {
	console.log("phrases", phrases)
	console.log('sources', sources)
	console.log('conceptLogic', conceptLogic)

	if ((sources.length > 0) && (concepts.length > 0 || phrases.length > 0)) {
		let URL = []
		let queryString = ''

		if(sources.length === 0){
			URL.push('sources=all')
		} else {
			URL.push('sources=' + sources.join(','))
		}

		if(selectedDay.day && selectedDay.month && selectedDay.year){
			URL.push(`dateFrom=${selectedDay.day}.${selectedDay.month}.${selectedDay.year}`)
		}

		// if(phrases.length > 0){
		// 	phrases.forEach(phrase => {
		// 		if(phrase.type === 'text')
		// 			queryString += `(${phrase.text})`
		// 		if(phrase.type === 'bool'){
		// 			switch (phrase.text) {
		// 				case "AND": {
		// 					queryString += ` `
		// 					break
		// 				}
		// 				case "NOT": {
		// 					queryString += `-`
		// 					break
		// 				}
		// 				default: {//OR
		// 					queryString += `,`
		// 				}
		// 			}
		// 		}
		// 	})
		// }
		// if(phrases.length > 0 && concepts.length > 0){
		// 	switch (conceptLogic) {
		// 		case "AND": {
		// 			queryString += ` `
		// 			break
		// 		}
		// 		case "NOT": {
		// 			queryString += `-`
		// 			break
		// 		}
		// 		default: {//OR
		// 			queryString += `,`
		// 		}
		// 	}
		// }
		// if(concepts.length > 0){
		// 	queryString += 'Концепт(.идентификатор=('
		// 	concepts.forEach((concept, index) => {
		// 		if(index === 0)
		// 			queryString += `"${concept.id}"`
		// 		else
		// 			queryString += `,"${concept.id}"`
		// 	})
		// 	queryString += ')'
		// }
		let qArray = [...phrases]
		if(phrases.length > 0 && concepts.length > 0)
			qArray = [...qArray, conceptLogic, ...concepts]
		else if(concepts.length > 0)
			qArray = [...concepts]

		// console.log(qArray)
		URL.push('q='+JSON.stringify(qArray))
		return <Link to={`/stream/content?${URL.join('&')}`} className="btn stream-finish-btn">Сформировать ленту</Link>
	}

	return <></>;
}

const PageStream = () => {
	const user = useSelector(state => state.user.value)
	const [phrases, setPhrases] = useState([])
	const savedConcepts = useSelector(state => state.user.savedConcepts)
	const [search, setSearch] = useSearchParams();
	const [conceptSelect, setConceptSelect] = useState([]);
	const [conceptsLogic, setConceptLogic] = useState({text: 'OR', type: 'bool'})
	const [platforms, setPlatforms] = useState([])
	const [platformsSearchState, setPlatformsSearchState] = useState('init')
	const [selectedPlatformList, setSelectedPlatformList] = useState({})
	const [fetchStat, setFetchStat] = useState(false)
	const [mainStat, setMainStat] = useState({docs: '', sources: ''})
	const [selectedDay, setSelectedDay] = useState( null )
	const calendarRef = useRef();
	const calendarWrapperRef = useRef();

	useEffect(() => {
		// Клик вне ||| для того чтобы скрыть календарь
		const event = (e) => {
			if(e.target.closest('.calendar_wrapper') !== calendarWrapperRef.current){
				calendarRef.current.classList.remove('active')
			}
		}
		document.addEventListener("click", event);
		return () => {
			document.removeEventListener("click", event)
		}
	}, [])

	useEffect(() => {// проверка созраненных концептов
		if (search.get('concept') && Array.isArray(savedConcepts)) {
			const concept = savedConcepts.find(c => c.concept_id === search.get('concept'))
			if (concept)
				setConceptSelect([{id: concept.concept_id, name: concept.name}])
		}
	}, [savedConcepts])

	useEffect(() => {// получаем статистику
		setFetchStat(true)
		APIClient.query({
			query: DOCUMENT_AND_STORIES_STAT,
		})
			.then((res) => {
				if (res.data) {
					setMainStat({
						docs: res.data.paginationStory.showTotal,
						sources: res.data.paginationPlatform.total
					})
				}
			})
			.finally(() => {
				setFetchStat(false)
			})
	}, [])

	const removeConcept = (index) => {
		setConceptSelect(conceptSelect.filter((itm, _index) => index !== _index))
	}

	const changeLogic = (index) => {
		setPhrases(phrases.map((ph, i) => {
			if(i === index){
				if(ph.text === 'AND')
					ph.text = 'OR'
				else if(ph.text === 'OR')
					ph.text = 'NOT'
				else if(ph.text === 'NOT')
					ph.text = 'AND'
			}
			return ph
		}))
	}

	const changeConceptLogic = () => {
		if(conceptsLogic.text === 'AND')
			conceptsLogic.text = 'OR'
		else if(conceptsLogic.text === 'OR')
			conceptsLogic.text = 'NOT'
		else if(conceptsLogic.text === 'NOT')
			conceptsLogic.text = 'AND'

		setConceptLogic({...conceptsLogic})
	}
	const removePhrase = (index) => {
		if(index > 0){
			setPhrases(phrases.filter((ph, i) => (index !== i && index-1 !== i)))
		}else if(index === 0 && phrases.length > 1){
			setPhrases(phrases.filter((ph, i) => (index !== i && index+1 !== i)))
		}else if(index === 0 && phrases.length === 1)
			setPhrases([])
	}

	const setAllPlatforms = () => {
		setPlatformsSearchState('all')
	}

	const setPlatformsFromObject = (obj) => {
		if(obj === 'all')
			setAllPlatforms()
		else if(typeof obj === 'object'){
			if(Object.keys(obj).length > 0)
				setPlatformsSearchState('selected')
			setPlatforms(Object.keys(obj))
		}
	}

	const setPlatformsFromSaved = ({list, platforms}) => {
		setSelectedPlatformList({name: list, platforms: platforms.split('|')})
		setPlatforms(platforms.split('|'))
		setPlatformsSearchState('fromSaved')
	}

	const removePlatformSelection = () => {
		setSelectedPlatformList({})
		setPlatformsSearchState('init')
	}

	return <main className='main-content content visible'>

		<QueryStringConstructor phrases={phrases}
		                        setPhrases={setPhrases}
		                        searchSelect={conceptSelect}
		                        setSearchSelect={setConceptSelect}/>

		{(phrases.length > 0 || conceptSelect.length > 0) &&
			<>
				<div className='title-mid c-main-dark'>
					Ваш поисковый запрос:
					<Tooltip>
						Это сформированный поисковый запрос. <br />
						<span className='search-phrases__item search-phrases__item--text text--modal' style={{height: "auto"}}>В голубой</span> рамке указаны
						полнотекстовые поисковые фразы. <br />
						<span className="search-phrases__item title search-phrases__item--concept text--modal" style={{height: "auto"}}>В серой</span> рамке -
						концепты.<br />
						При нажатии на логический оператор ( &nbsp;
						<div className="search-phrases__item search-phrases__item--bool OR">OR</div>
						<div className="search-phrases__item search-phrases__item--bool NOT">NOT</div>
						<div className="search-phrases__item search-phrases__item--bool AND">AND</div>
						) между фразами, вы можете менять логику связей фраз или концептов.
					</Tooltip>
				</div>
				<div className='search-phrases'>
					{phrases.map((phrase, index) => {
						if (phrase.type === 'bool') {
							return <div key={`${phrase.text}_${index}`}
							            onClick={e => changeLogic(index)}
							            className={`search-phrases__item search-phrases__item--bool ${phrase.text}`}>{phrase.text}</div>
						}
						return <div key={`${phrase.text}_${index}`}
						            className='search-phrases__item title search-phrases__item--text'>{phrase.text}
							<div onClick={e => {
								removePhrase(index)
							}} className='cross'/>
						</div>
					})}

					{phrases.length > 0 && conceptSelect.length > 0 &&
						<div key={`concept_logic_${conceptsLogic.text}`}
						     onClick={e => changeConceptLogic()}
						     className={`search-phrases__item search-phrases__item--bool ${conceptsLogic.text}`}>{conceptsLogic.text}</div>
					}

					{conceptSelect.map((concept, index) => {
						return <div key={concept.id}
						            className='search-phrases__item title search-phrases__item--concept'>{concept.text}
							<div onClick={e => {
								removeConcept(index)
							}} className='cross'/>
						</div>
					})}
				</div>
			</>
		}

		<hr/>
		{/*выбор платформы*/}
		<div>
			{(platformsSearchState === 'init' || platformsSearchState === 'selected') &&
				<PlatformsPage asSelector={true} sendSelectedPlatforms={setPlatformsFromObject} sendAsList={setPlatformsFromSaved} />
			}
			{platformsSearchState === 'fromSaved' &&
				<PlatformFromSaved platforms={selectedPlatformList.platforms} name={selectedPlatformList.name} crossClick={removePlatformSelection} />
			}
			{platformsSearchState === 'all' &&
				<div>
					<h1 className="title-big c-main-dark" style={{marginBottom: "1.75rem"}}>Выбор источников:</h1>
					<div>
						<div className="title c-main-dark">Выбрать из списка сохраненных источников:</div>
					</div>

					<div>
						<div className="selected_platform_list">
							<span>Выбраны все источники</span>
							<div className="cross" onClick={ () => {setPlatformsSearchState('init')} }></div>
						</div>
					</div>

					<div className="text" style={{marginTop: "1.75rem"}}>
						<p>Количество выбранных источников: {mainStat.sources}</p>
					</div>
				</div>
			}
		</div>
		{/*дата начала отбора публикаций*/}
		<hr style={{margin: "3rem 0"}}/>
		<div>
			<div className='flex_row flex_row--to_left fle' style={{gap: "2.5rem"}}>
				{selectedDay
					? <>
						<div className='text--as_title text--dark_blue'>Публикации начиная с: </div>
						<div className='text--as_title text--dark_blue'>{('0'+selectedDay.day).slice(-2) + '.' + ('0'+selectedDay.month).slice(-2) + '.' + selectedDay.year}</div>
						<div className="calendar_wrapper text--blue" ref={calendarWrapperRef}>
							<span onClick={() => {calendarRef.current.classList.toggle('active')}}>Изменить период</span>
							<div ref={calendarRef} className="hidden_calendar">
								<Calendar
									value={selectedDay}
									onChange={setSelectedDay}
									shouldHighlightWeekends
									maximumDate={utils().getToday()}
									locale={CALENDAR_LOCALE}
									colorPrimary="#5038EC" // added this
									colorPrimaryLight="#EFEFFF" // and this
								/>
							</div>
						</div>
					</>
					: <>
						<div ref={calendarWrapperRef} className='calendar_wrapper' style={{position: "relative"}}>
							<button className='btn small' onClick={() => {calendarRef.current.classList.toggle('active')}}>
								Установить дату начала отбора публикаций
							</button>
							<div ref={calendarRef} className="hidden_calendar">
								<Calendar
									value={selectedDay}
									onChange={setSelectedDay}
									shouldHighlightWeekends
									maximumDate={utils().getToday()}
									locale={CALENDAR_LOCALE}
									colorPrimary="#5038EC" // added this
									colorPrimaryLight="#EFEFFF" // and this
								/>
							</div>
						</div>

						<p className='text--gray'>Если период не установлен поиск будет осуществляться из всех возможных публикаций. </p>
					</>
				}
			</div>
		</div>
		<hr style={{margin: "3rem 0"}}/>

		{platformsSearchState !== 'init' && (phrases || conceptSelect) &&
			<ShowBtn
				conceptLogic={conceptsLogic}
				concepts={conceptSelect}
				phrases={phrases}
				sources={platforms}
				selectedDay={selectedDay}
			/>
		}

	</main>
}
export default PageStream;