import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import {HandySvg} from 'handy-svg';
import {useDispatch, useSelector} from "react-redux";
import {setAuthData, setHistory, setSavedConcepts, setUserData} from "../../reducers/userSlice";
import {BACK_HOST, TALISMAN_HOST} from "../../Consts";
import { useCookies } from 'react-cookie'

import svg_arrow from '../../ico/arrow.svg';
import svg_settings from '../../ico/settings.svg';
import logo from '../../ico/logo.svg'
import {analyticsBACK, APIClient} from "../../ApiEndpoints";
import {isArray} from "@apollo/client/cache/inmemory/helpers";
import Skeleton from "react-loading-skeleton";
import {setStreamList} from "../../reducers/streamsSlice";
import {CONCEPTS_SHORT_INFO} from "../../graphql/conceptQueries";
import ico_doc from "../../ico/doc.svg";
import Modal from "../ui/Modal";

const Header = () => {
	const user = useSelector((state) => state.user.value)
	const savedConcepts = useSelector((state) => state.user.savedConcepts)
	const savedStreams = useSelector(state => state.stream.list)
	const [savedStreamsUpdates, setStreamUpdates] = useState(false)
	const [modalOpened, setOpenModal] = useState(false)
	const [inviteFails, setInviteFails] = useState([])
	const [inviteSuccess, setInviteSuccess] = useState([])
	const headerBlock = useRef()
	const headerSettingBlock = useRef()
	const dispatch = useDispatch()
	const [cookies, setCookie] = useCookies(['theme'])

	useEffect(() => {
		//клик вне
		const click = (e) => {
			if (headerBlock.current && !e.target.closest('.header')) {
				headerSettingBlock.current.classList.remove('active');
			}
		}
		document.addEventListener("click", click);
		let prevScroll = null;

		const scroll = (e) => {
			let scroll = document.documentElement.scrollTop;

			if (prevScroll !== null && headerBlock.current) {
				if (scroll > prevScroll && scroll > 0) {
					headerBlock.current.classList.add("header--hide")
				} else {
					headerBlock.current.classList.remove("header--hide")
				}
			}

			prevScroll = scroll;
		}
		document.addEventListener("scroll", scroll)

		return () => {
			document.removeEventListener("click", click)
			document.removeEventListener("scroll", scroll)
		}
	}, [])

	useEffect(() => {

		if(user.id) {
			analyticsBACK.get('/concept/saved', { //getting saved concepts
				params: {
					userID: user.id
				}
			})
				.then((resp) => {
					if(isArray(resp.data)){
						const ids = resp.data.map(concept => concept.concept_id)
						APIClient.query({
							query: CONCEPTS_SHORT_INFO,
							variables:{
								ids: (ids.length > 0)? ids : null
							}
						})
							.then(resp => {
								const concepts = resp.data.listConceptById.map(concept => {
									return {
										concept_id: concept.id,
										...concept
									}
								})
								dispatch(setSavedConcepts(concepts))
							})
							.catch(resp => {
								dispatch(setSavedConcepts([]))
								console.log('ther`s no saved concepts')
							})
						// dispatch(setSavedConcepts(resp.data))
					}
					else
						dispatch(setSavedConcepts([]))
				})
				.catch((resp) => {
					console.error(resp)
				})

			analyticsBACK.get('/stream', {//refreshing streams
				params: {
					userID: user.id
				}
			})
				.then((resp) => {
					let updatesCount = 0

					if(isArray(resp.data)){
						dispatch(setStreamList(resp.data))
						resp.data.forEach(stream => {
							if(stream.updated){
								updatesCount += 1
							}
						})
						setStreamUpdates(updatesCount)
					}
					else{
						dispatch(setStreamList('none'))
						setStreamUpdates(updatesCount)
					}
				})
				.catch((resp) => {
					console.error(resp)
				})

			analyticsBACK.get('/history', {
				params: {
					userID: user.id,
				}
			})
				.then(resp => {
					if(resp.data)
						dispatch(setHistory(resp.data))
				})
				.catch(resp => {
					console.error('Ошибка при получении истории', resp)
				})
		}
	},[user])

	useEffect(() => {
		let updatesCount = 0
		if(Array.isArray(savedStreams)) {
			savedStreams.forEach(stream => {
				if (stream.updated) {
					updatesCount += 1
				}
			})
			setStreamUpdates(updatesCount)
		}
	}, [savedStreams])

	useEffect(() => {//select theme
		document.querySelector('body').classList.remove("theme-dark")
		document.querySelector('body').classList.remove('theme-light')
		document.querySelector('body').classList.add('theme-' + ((cookies.theme)? cookies.theme : 'light'))
	}, [cookies.theme])

	function setting_toggle(e) {
		headerSettingBlock.current.classList.toggle('active');
	}

	const setSize = (size) => (e) => {
		let btn = e.target;

		btn.parentElement.querySelectorAll('.header-setting-itm__btn').forEach(function (itm) {
			itm.classList.remove('active');
		})
		btn.classList.add('active');

		document.querySelector('body').style.fontSize = size;

	}
	const setTheme = (theme) => (e) => {
		if(cookies.theme){
			switch (theme) {
				case "theme-dark": {
					setCookie('theme', 'dark', {maxAge: "31536000"})
					break
				}
				default: {
					setCookie('theme', 'light', {maxAge: "31536000"})
					break
				}
 			}
		}else{
			setCookie('theme', 'light')
		}
	}

	const logout = (e) => {
		e.preventDefault()
		analyticsBACK.get('/logout')
			.then((resp) => {
				if(resp.status === 200) {
					dispatch(setAuthData(false))
					dispatch(setUserData(null))
				}
			})
	}

	const handleSubmit = (event) => {
		setInviteFails([])
		event.preventDefault()
		analyticsBACK.post('/invite', {
			userID: user.id,
			emailTo: event.target.email.value,
			userName: user.name,
		})
			.then(resp => {
				setInviteFails([])
				setInviteSuccess(['Сообщение успешно отправлено'])
				console.log(resp)
			})
			.catch(resp => {
				setInviteSuccess([])
				setInviteFails(['Ошибка при отправке приглашения'])
				console.error("Sending invite fail",resp)
			})
	}

	return (
		<div className="header-wrapper">
			<header ref={headerBlock} className="header">
				<div className="content visible">
					<div className="header-content">
						<div className="header-content__left">

							<Link to="/">
								<HandySvg src={logo} width={143} height={35} className="header__logo"/>
							</Link>

							{!user.local &&
								<a href={TALISMAN_HOST} target="_blank" rel="noreferrer"
								   className="header-link with-arrow link-to-talisman">
									<HandySvg src={svg_arrow} width={20} height={18} />
									<span>Перейти в Талисман</span>
								</a>
							}
						</div>

						<button className="header-ico with-hover" onClick={setting_toggle}>
							<HandySvg src={svg_settings} width={24} height={24}/>
						</button>

						<div className="header-content__right">
							<div className="header-user">
								<span className="header-user-title">Источники</span>
								<div className="header-user-list">
									<Link to={"/platforms"} className='header-user-link'>Список источников</Link>
									<Link to={"/platforms/saved"} className='header-user-link'>Избранные источники</Link>
								</div>
							</div>

							<div className="header-link">
								<Link to="/concept/remembered" className="header-link">
									<span>Избранные объекты</span>
									{savedConcepts.length >= 0 && isArray(savedConcepts)
										?<span className="pin">{savedConcepts.length}</span>
										:<Skeleton width='24px' height="24px" circle={true} className='pin' />
									}
								</Link>
							</div>


							<Link to="/stream/all" className="header-link">
								<span>Мои ленты</span>
								{savedStreams === false
									? <Skeleton width='24px' height='24px' circle={true} className="pin" baseColor='#FF9500' />
									:savedStreamsUpdates > 0
										?<span className="pin active">{savedStreamsUpdates}</span>
										: ''
								}
							</Link>

							<div className="header-user">
								<span className="header-user-title">{user.name}</span>
								<div className="header-user-list">
									<a href="#" onClick={(e) => {logout(e)}} className="header-user-link">Выйти</a>
									<a href="#" onClick={() => setOpenModal(!modalOpened)} className='header-user-link'>Пригласить пользователя</a>
								</div>
							</div>

						</div>

						<div ref={headerSettingBlock} className="header-setting">
							<div className="header-setting-itm">
								<div className="header-setting-itm__title title">Размер шрифта</div>
								<div className="header-setting-itm__itms">
									<span className="header-setting-itm__btn setting-size-normal active"
									      onClick={setSize("1em")}>А</span>
									<span className="header-setting-itm__btn setting-size-mid"
									      onClick={setSize("1.2em")}>А</span>
									<span className="header-setting-itm__btn setting-size-big"
									      onClick={setSize("1.4em")}>А</span>
								</div>
							</div>

							<div className="header-setting-itm">
								<div className="header-setting-itm__title title">Цветовая схема</div>
								<div className="header-setting-itm__itms">
									<span className={"header-setting-itm__btn setting-theme-light" + ((!cookies.theme || cookies.theme === 'light')? ' active' : '')}
									      onClick={setTheme('theme-light')}>Светлая</span>
									<span className={"header-setting-itm__btn setting-theme-dark" + ((cookies.theme && cookies.theme === 'dark')? ' active' : '')}
									      onClick={setTheme('theme-dark')}>Темная</span>
								</div>
							</div>
						</div>

					</div>
				</div>
			</header>

			<Modal  opened={modalOpened} setOpened={setOpenModal}>
				<div className="title c-main-dark">Введите емейл пользователя</div>
				<p style={{margin: '20px 0'}}>На данный email придет индивидуальная ссылка с приглашением на регистрацию. Пользователю будет необходимо пройти процедуру регистрацию, перейдя по данной ссылке.</p>
				{inviteFails.length > 0 &&
					inviteFails.map(resp => {
						return <p className='error'>{resp}</p>
					})
				}
				{inviteSuccess.length > 0 &&
					inviteSuccess.map(resp => {
						return <p>{resp}</p>
					})
				}
				<form className='nice-form' onSubmit={e => handleSubmit(e)}>
					<div className="cols cols-2 p10">
						<div className="col">
							<label className="input-wrap">
								<input
									name='email'
									type='email'
									placeholder=" "
									required={true}
								/>
								<span className="input-wrap__label">Email</span>
							</label>
						</div>
						<div className="col">
							<button type='submit' className='btn small'>Отправить</button>
						</div>
					</div>
				</form>
			</Modal>
		</div>
	);
}

export default Header;