import React from "react";

const PrettyInput = ({placeholder, value, setValue, name}) => {

	return <div className="nice-form">
		<label className="input-wrap with-ico">
			<input
				type="text"
				name={name}
				placeholder=" "
				onInput={e => setValue(e.target.value)}
				value={value}
			/>
			<div className="input-wrap__label">
				{placeholder}
			</div>
		</label>
	</div>
}

export default PrettyInput