import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import SavedSteamTable from "../../components/stream/SavedSteamTable";

const PageAllStreams = () => {

	useEffect(() => {
		document.title = 'Мгимо аналитика: все ленты'
	}, [])

	return (
		<main className={'main-content'}>
			<div className="content visible">
				<h1 className="title-big main-margin c-main-dark">Мои ленты</h1>

				<SavedSteamTable />

				<div className="ta-c">
					<Link key='to_all' to="/stream" className="btn">Сформировать новую ленту</Link>
				</div>

			</div>
		</main>
	);
}

export default PageAllStreams