import {gql} from "@apollo/client";
import {DOC_METRIC_INFO, DOCUMENT_INFO} from "./fragments";

export const PLATFORM_SEARCH = gql`
    query PlatformSearch($q: String){
        paginationPlatform(filterSettings:{searchString: $q}){
            total
            listPlatform{
                id
                name
                country
            }
        }
    }
`
export const PLATFORM_SEARCH_COUNTRIES = gql`
    query PlatformSearchByCountries($q: String, $countries: [String!]){
        paginationPlatform(filterSettings:{searchString: $q, country: $countries}){
            total
            listPlatform{
                id
                name
                country
            }
        }
    }
`

export const COUNTRIES_LIST = gql`
    query Countries{
        paginationCountry(filterSettings: {}){
            total
            listCountry
        }
    }
`

export const CONCEPTS = gql`
    query Concepts($ids: [ID!]!){
        listConceptById(ids: $ids){
            id
            name
        }
    }
`

export const PLATFORM = gql`
    query Platform($id: ID!){
        platform(id:$id){
            id
            name
            country
            language
        }
    }
`

export const STORIES_BY_CONCEPTS_AND_PLATFORMS = gql`
    query StoriesByConceptsAndPlatforms($concepts: [ID!], $platforms: [ID!], $limit: Int, $offset: Int){
        paginationStory(filterSettings:{concepts: $concepts, platforms: $platforms}, limit: $limit, offset: $offset, extraSettings: {}){
            showTotal
            listPlatformCountFacet{
              value {
                id
                name
                country
              }
              count
            }
            listStory{
                main{
                    id
                    title
                    preview
                    externalUrl
                    systemRegistrationDate
                    metadata{
                        platform{
                            id
                            name
                            country
                        }
                    }
                }
            }
        }
    }
`
export const STORIES_BY_CONCEPTS_AND_PLATFORMS_DATES = gql`
    query StoriesByConceptsAndPlatforms($concepts: [ID!], $platforms: [ID!], $limit: Int, $offset: Int, $date_start: UnixTime, $date_end: UnixTime){
        paginationStory(filterSettings:{concepts: $concepts, platforms: $platforms, publicationDate:{start: $date_start, end: $date_end}, childVisibility: all}, limit: $limit, offset: $offset, extraSettings:{}){
            showTotal
            listPlatformCountFacet{
              value {
                id
                name
                country
              }
              count
            }
            listStory{
                main{
                   ...DocInfo
                    metadata{
                        platform{
                            id
                            name
                            country
                        }
                    }
                }
            }
        }
    }
    ${DOCUMENT_INFO}
    ${DOC_METRIC_INFO}
`

export const STORIES_BY_CONCEPTS_ALL_PLATFORMS = gql`
    query StoriesAllPlatforms($concepts: [ID!], $limit: Int, $offset: Int){
        paginationStory(filterSettings:{concepts: $concepts, childVisibility: all}, limit: $limit, offset: $offset, extraSettings:{}){
            showTotal
            listPlatformCountFacet{
              value {
                id
                name
                country
              }
              count
            }
            listStory{
                main{
                    ...DocInfo
                    metadata{
                        account{
                            id
                            name
                            url
                        }
                        platform{
                            id
                            name
                            country
                        }
                    }
                }
            }
        }
    }
    ${DOCUMENT_INFO}
    ${DOC_METRIC_INFO}
`

export const STORIES_BY_QUERY_AND_PLATFORMS = gql`
    query StoriesByQueryPlatforms($q: String, $limit: Int, $offset: Int, $platforms: [ID!], $date_start: UnixTime, $date_end: UnixTime){
        paginationStory(
            filterSettings:{searchString: $q, platforms: $platforms, publicationDate:{start: $date_start, end: $date_end}, childVisibility: all}, 
            limit: $limit, 
            offset: $offset,
            sortField: publicationDate,
            direction: descending,
            extraSettings:{}
            ){
            showTotal
            listPlatformCountFacet{
              value {
                id
                name
                country
              }
              count
            }
            listStory{
                main{
                    ...DocInfo
                    metadata{
                        account{
                            id
                            name
                            url
                        }
                        platform{
                            id
                            name
                            country
                        }
                    }
                }
            }
        }
    }
    ${DOCUMENT_INFO}
    ${DOC_METRIC_INFO}
`

export const STORIES_BY_CONCEPTS_ALL_PLATFORMS_DATES = gql`
    query StoriesAllPlatforms($concepts: [ID!], $limit: Int, $offset: Int, $date_start: UnixTime, $date_end: UnixTime){
        paginationStory(filterSettings:{concepts: $concepts, publicationDate:{start: $date_start, end: $date_end}, childVisibility: all}, limit: $limit, offset: $offset, extraSettings:{}){
            showTotal
            listPlatformCountFacet{
              value {
                id
                name
                country
              }
              count
            }
            listStory{
                main{
                    ...DocInfo
                    metadata{
                        platform{
                            id
                            name
                            country
                        }
                    }
                }
            }
        }
    }
    ${DOCUMENT_INFO}
    ${DOC_METRIC_INFO}
`

export const STREAM_STORIES_STATISTIC_ALL = gql`
    query StreamStoriesStat($concepts: [ID!],$date0: UnixTime, $date1: UnixTime, $date2: UnixTime, $date3: UnixTime, $date4: UnixTime, $date5: UnixTime, ,$date6: UnixTime, $date7: UnixTime, $date8: UnixTime, $date9: UnixTime, $date10: UnixTime, $date11: UnixTime, $date12: UnixTime){
        DocsDate_0: paginationStory(extraSettings:{},limit: 1, filterSettings:{concepts: $concepts ,documentType: text, publicationDate: {start: $date1, end: $date0}}){
            total
            showTotal
        }
        DocsDate_1: paginationStory(extraSettings:{}, limit: 1, filterSettings:{concepts: $concepts ,documentType: text, publicationDate: {start: $date2, end: $date1}}){
            total
            showTotal
        }
        DocsDate_2: paginationStory(extraSettings:{}, limit: 1, filterSettings:{concepts: $concepts ,documentType: text, publicationDate: {start: $date3, end: $date2}}){
            total
            showTotal
        }
        DocsDate_3: paginationStory(extraSettings:{}, limit: 1, filterSettings:{concepts: $concepts ,documentType: text, publicationDate: {start: $date4, end: $date3}}){
            total
            showTotal
        }
        DocsDate_4: paginationStory(extraSettings:{}, limit: 1, filterSettings:{concepts: $concepts ,documentType: text, publicationDate: {start: $date5, end: $date4}}){
            total
            showTotal
        }
        DocsDate_5: paginationStory(extraSettings:{}, limit: 1, filterSettings:{concepts: $concepts ,documentType: text, publicationDate: {start: $date6, end: $date5}}){
            total
            showTotal
        }
        DocsDate_6: paginationStory(extraSettings:{}, limit: 1, filterSettings:{concepts: $concepts ,documentType: text, publicationDate: {start: $date7, end: $date6}}){
            total
            showTotal
        }
        DocsDate_7: paginationStory(extraSettings:{}, limit: 1, filterSettings:{concepts: $concepts ,documentType: text, publicationDate: {start: $date8, end: $date7}}){
            total
            showTotal
        }
        DocsDate_8: paginationStory(extraSettings:{}, limit: 1, filterSettings:{concepts: $concepts ,documentType: text, publicationDate: {start: $date9, end: $date8}}){
            total
            showTotal
        }
        DocsDate_9: paginationStory(extraSettings:{}, limit: 1, filterSettings:{concepts: $concepts ,documentType: text, publicationDate: {start: $date10, end: $date9}}){
            total
            showTotal
        }
        DocsDate_10: paginationStory(extraSettings:{}, limit: 1, filterSettings:{concepts: $concepts ,documentType: text, publicationDate: {start: $date11, end: $date10}}){
            total
            showTotal
        }
        DocsDate_11: paginationStory(extraSettings:{}, limit: 1, filterSettings:{concepts: $concepts ,documentType: text, publicationDate: {start: $date12, end: $date11}}){
            total
            showTotal
        }
    }
`
export const STREAM_STORIES_STATISTIC_BY_PLATFORMS = gql`
    query StreamStoriesStatByPlatforms($q: String, $platforms: [ID!],$date0: UnixTime, $date1: UnixTime, $date2: UnixTime, $date3: UnixTime, $date4: UnixTime, $date5: UnixTime, ,$date6: UnixTime, $date7: UnixTime, $date8: UnixTime, $date9: UnixTime, $date10: UnixTime, $date11: UnixTime, $date12: UnixTime){
        DocsDate_0: paginationStory(extraSettings:{},limit: 1, filterSettings:{searchString: $q, platforms: $platforms,documentType: text, publicationDate: {start: $date1, end: $date0}}){
            total
            showTotal
        }
        DocsDate_1: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentType: text, publicationDate: {start: $date2, end: $date1}}){
            total
            showTotal
        }
        DocsDate_2: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentType: text, publicationDate: {start: $date3, end: $date2}}){
            total
            showTotal
        }
        DocsDate_3: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentType: text, publicationDate: {start: $date4, end: $date3}}){
            total
            showTotal
        }
        DocsDate_4: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentType: text, publicationDate: {start: $date5, end: $date4}}){
            total
            showTotal
        }
        DocsDate_5: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentType: text, publicationDate: {start: $date6, end: $date5}}){
            total
            showTotal
        }
        DocsDate_6: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentType: text, publicationDate: {start: $date7, end: $date6}}){
            total
            showTotal
        }
        DocsDate_7: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentType: text, publicationDate: {start: $date8, end: $date7}}){
            total
            showTotal
        }
        DocsDate_8: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentType: text, publicationDate: {start: $date9, end: $date8}}){
            total
            showTotal
        }
        DocsDate_9: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentType: text, publicationDate: {start: $date10, end: $date9}}){
            total
            showTotal
        }
        DocsDate_10: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentType: text, publicationDate: {start: $date11, end: $date10}}){
            total
            showTotal
        }
        DocsDate_11: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentType: text, publicationDate: {start: $date12, end: $date11}}){
            total
            showTotal
        }
    }
`