import React, {useRef, useState} from "react";
import PrettyInput from "../ui/PrettyInput";
import {analyticsBACK} from "../../ApiEndpoints";
import {useDispatch, useSelector} from "react-redux";
import {setSavedPlatforms} from "../../reducers/platformSlice";

const PlatformsSaveButton = ({platforms}) => {
	const [setName, saveSetName] = useState()
	const modalRef = useRef()
	const user = useSelector(state => state.user.value)
	const [messages, setMessages] = useState([])
	const [saving, setSaving] = useState(false)
	const dispatch = useDispatch()

	const savePlatforms = () => {
		setSaving(true)
		setMessages([])
		analyticsBACK.post('/platforms', {
			userID: user.id,
			name: setName,
			platforms: Object.keys(platforms).join('|'),
		})
			.then((resp) => {
				dispatch(setSavedPlatforms(resp.data.platformsList))
				console.log('PLATFORMS_SAVE', resp)
				setMessages([{type: 'success', message: 'Набор источников успешно сохранен'}])
			})
			.catch((resp) => {
				console.log('PLATFORMS_SAVE', resp)
				setMessages([{type: 'error', message: resp.response.data.message}])
			})
			.finally(() => setSaving(false))
	}

	return <div>
		<p>&nbsp;</p>
		<div className='flex_row flex_row--sp_center'>
			<button className="btn big" onClick={() => modalRef.current.classList.add('active')}>Сохранить список в избранные</button>
		</div>

		<div className="modal_holder" ref={modalRef}>
			<div className="modal_bg" onClick={()=>modalRef.current.classList.remove('active')}></div>
			<div className="modal_content modal_content--small">
				<div className='modal_close' onClick={()=>modalRef.current.classList.remove('active')}>
					<div className="cross" onClick={()=>modalRef.current.classList.remove('active')}></div>
				</div>

				<div className='title'>Введите название списка избранных источников</div>
				<PrettyInput placeholder={"Название списка источников"} name={'name'} setValue={saveSetName} value={setName}/>
				{messages[0] && messages[0].type === 'success'
					? <div>{messages[0].message}</div>
					: <div>
						{messages[0] && messages[0].type === 'error' &&
							<div className='error'>{messages[0].message}</div>
						}
						<div>&nbsp;</div>
						<div className="flex_row flex_row--sp_center" aria-disabled={saving}>
							<button className='btn small' onClick={() => savePlatforms()}>Сохранить</button>
						</div>
					</div>
				}
			</div>
		</div>
	</div>
}

export default PlatformsSaveButton