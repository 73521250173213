import React, {useRef, useState} from "react";

export default function PrettySelect({name, onChange = false, addClass = '', options = [], placeholder = '', style = {}}) {
	const selectRef = useRef()
	const [selectedText, setSelectedText] = useState(false)

	const makeOptions = () => {
		return options.map((option) => {
			if(option.text){
				return <option key={option.text} value={((option.value)?? option.text)}>{option.text}</option>
			}
			return ''
		})
	}

	const selectOption = ({value, text}) => {
		setSelectedText(text)
		selectRef.current.value = (value)?? text
		selectRef.current.classList.remove('active')
		if(typeof onChange === 'function')
			onChange({value, text})
	}

	const makeFakeOptions = () => {
		return options.map((option) => {
			if(option.text){
				return <div
					key={option.text}
					className='pretty_select__list_item'
					onClick={() => {selectOption(option)}}
				>{option.text}</div>
			}
			return ''
		})
	}
	return <div for={name} ref={selectRef} style={style} className={'pretty_select ' + addClass}>
		<select name={name}  id={name}>
			{makeOptions()}
		</select>
		<div className='pretty_select__selected' onClick={() => {selectRef.current.classList.toggle('active')}}>
			{selectedText
				? selectedText
				: placeholder
			}
		</div>
		<div className='pretty_select__list'>
			{makeFakeOptions()}
		</div>
	</div>
}