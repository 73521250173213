import axios from "axios";
import {BACK_HOST, FRONT_HOST, POSSIBLE_TYPES} from "./Consts";
import {ApolloClient, InMemoryCache} from "@apollo/client";
import {graphqlSync} from "graphql";
// import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";

// import schema from "./schema.json"

export const analyticsBACK = axios.create({
	baseURL: BACK_HOST+"/api",
	timeout: 120000,
	withCredentials: true,
	headers: {
		"Accept": "application/json, text/plain, */*"
	}
})

// const fragment = new
// const fragmentMatcher = new IntrospectionFragmentMatcher({schema});

const cache = new InMemoryCache({
	possibleTypes: POSSIBLE_TYPES,
	typePolicies: {
		FlatDocumentStructure: {
			keyFields: ["id", "documentId"]
		}
	}
})

export const APIClient = new ApolloClient({
	uri: BACK_HOST+'/api/graphQL',
	cache: cache,
	origin: FRONT_HOST,
	credentials: 'include',
	connectToDevTools: true,
})

export const LocalesClient = axios.create({
	baseURL: BACK_HOST+ '/locales/ru',
	timeout: 120000,
	withCredentials: true,
	headers: {
		"Accept": "application/json, text/plain, */*"
	}
})