import React, {useEffect, useState} from 'react';
import {Link, useSearchParams} from "react-router-dom";
import ListBlock from "../../components/ui/listBlock/ListBlock";
import SmartSearchMulti from "../../components/stream/SmartSearchMulti";
import {useDispatch, useSelector} from "react-redux";
import RememberList from "../../components/search/PememberList";
import {APIClient} from "../../ApiEndpoints";
import {CONNECTIONS_TYPES} from "../../graphql/conceptQueries";
import {setConnectionsTypes} from "../../reducers/conceptSlice";
import Skeleton from "react-loading-skeleton";

const ShowBtn = ({select}) => {
	if (Object.keys(select).length > 0) {
		return <Link to={`/connections/${select.map(concept => concept.id).join(',')}`} className="btn stream-finish-btn">Найти связи объектов</Link>
	}

	return <></>;
}

function Connections() {
	const [search, setSearch] = useSearchParams();
	const [searchSelect, setSearchSelect] = useState([]);
	const savedConcepts = useSelector(state => state.user.savedConcepts)
	const connectionsTypes = useSelector(state => state.concepts.connectionsType)
	const [selectedTypes, setSelectedTypes] = useState([])

	useEffect(() => {
		document.title = 'Мгимо аналитика: связи'
	}, [])

	useEffect(() => {
		if(search.get('concepts') && Array.isArray(savedConcepts)){
			const concept = savedConcepts.find(c => c.concept_id === search.get('concept'))
			if(concept)
				setSearchSelect([{id: concept.concept_id, name: concept.name, conceptType: concept.conceptType}])
		}
	}, [savedConcepts])

	const handleTypeItemClick = (typeItem) => {
		if(selectedTypes.find(itm => itm.id === typeItem.id)){
			console.log("typeItem", typeItem)
			console.log("selectedTypes", selectedTypes)
			setSelectedTypes(selectedTypes.filter(itm => itm.id !== typeItem.id))
		}
		else
			setSelectedTypes([...selectedTypes, typeItem])
	}

	return (
		<main className={'main-content'}>
			<div className="content visible">

				<div className="title c-main-dark">Выберите объект или несколько объектов для формирования связей:</div>

				<SmartSearchMulti select={searchSelect} setSelect={setSearchSelect}/>

				<ShowBtn select={searchSelect} />

				<ListBlock
					title="Ранее сформированные связи"
					link="/reports"
					link_name="Посмотреть все отчеты"
					type="files"
					filesType='connections'
					limit={5}
				/>
			</div>
		</main>
	);
}

export default Connections;