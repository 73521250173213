import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getSavedPlatforms} from "../../requests/platforms";
import Skeleton from "react-loading-skeleton";
import {setSavedPlatforms} from "../../reducers/platformSlice";
import PrettySelect from "../ui/PrettySelect";


export function SavedPlatformSelect ({setPlatformList, setAll}) {
	const [fetching, setFetching] = useState(false)
	const user = useSelector(state => state.user.value)
	const savedPlatforms = useSelector((state) => state.platform.saved)
	const dispatch = useDispatch()

	useEffect(() => {//получение сохраненных платформ
		if(user.id){
			if(savedPlatforms.length === 0){// если платформ в магазине нет, то получаем их
				setFetching(true)
				getSavedPlatforms(user.id)
					.then((resp) => {
						if(resp.data.platformsList) {
							dispatch(setSavedPlatforms(resp.data.platformsList))
						}
					})
					.catch((resp) => {
						console.error('SAVED_PLATFORMS_FETCH_ERROR', resp)
					})
					.finally(() => setFetching(false))
			}

		}
	}, [user, savedPlatforms]);
	
	const makeOptions = () => {
		if(savedPlatforms.length > 0){
			return savedPlatforms.map((list) => {
				return <option key={list.id} value="{list.id}">{list.name}</option>
			})
		}else{
			
		}
	}

	const handlePlatformListSelection = (val) =>{
		// console.log("handlePlatformListSelection", val)
		setPlatformList({list: val.text, platforms: (val.value)?? val.text})
	}

	return <div>
		<div className="title c-main-dark">Выбрать из списка сохраненных источников:</div>
		{fetching
			? <Skeleton  width={"635px"} height={"43px"}/>
			: <PrettySelect style={{width: '635px'}}
			                onChange={handlePlatformListSelection}
			                name='platformListSelect'
			                placeholder='Списки сохраненных источников'
			                options={savedPlatforms.map(
								(platform) =>
								{return {text: platform.name, value: platform.platformID}}
			                )}
			/>
		}
	</div>
}