import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import {Provider} from "react-redux";
import Store from './Store'
import {CookiesProvider} from 'react-cookie'

import './styles/style.scss'
import 'react-loading-skeleton/dist/skeleton.css'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<CookiesProvider defaultSetCookies={{path: '/'}}>
		<Provider store={Store}>
			<BrowserRouter>
				<App/>
			</BrowserRouter>
		</Provider>
	</CookiesProvider>
);