import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import SavedSteamTable from "../../components/stream/SavedSteamTable";

const ChoseStream = () => {
	useEffect(() => {
		document.title = 'Мгимо аналитика: выбор типа ленты'
	}, [])

	return <main className={'main-content'}>
		<div className="content visible">
			<h1 className={"title-big main-margin c-main-dark"}>Выберите тип ленты</h1>

			<div className="main-analytic">
				<div className="cols cols-2 p10">
					<div className="col">
						<Link className="btn" to="/stream">Обновляемая лента</Link>
						<div className="main-analytic-desc">Лента, которую можно сохранить, для отслеживания</div>
					</div>

					<div className="col">
						<Link className="btn" to="/concept">Не обновляемая лента</Link>
						<div className="main-analytic-desc">Лента, которую можно использовать для создания отчетов</div>
					</div>
				</div>
			</div>

			<div className={'title c-main-dark title--middle'}>Сохраненные ленты</div>
			<SavedSteamTable />
		</div>
	</main>
}

export default ChoseStream