import React, {useEffect, useRef, useState} from "react";
import {getPlatformsByIDs} from "../../requests/platforms";
import Skeleton from "react-loading-skeleton";
import PlatformsModalTable from "./PlatformsModalTable";

export default function PlatformsOnStream({platformsID}){
	const [fetchedPlatforms, setFetchedPlatforms] = useState([])
	const [fetching, setFetching] = useState(false)
	const modalRef = useRef()

	useEffect(() => {// получаем по списку информацию об источниках
		if(platformsID){
			setFetching(true)
			getPlatformsByIDs(platformsID)
				.then((resp) => {
					if(resp.data && resp.data.paginationPlatform && resp.data.paginationPlatform.listPlatform)
						setFetchedPlatforms(resp.data.paginationPlatform.listPlatform)
				})
				.catch((resp) => {
					console.error("getPlatformsByIDsFail", resp)
				})
				.finally(() => {setFetching(false)})
		}
	}, [platformsID]);



	return <div className='flex_row flex_row--gap_w_double' style={{margin: "3rem 0"}}>
		<p className='text--as_title'>Выбрано источников: {platformsID.length}</p>
		{fetching
			? <Skeleton width={"270px"} />
			: <button className="text--blue text_button" onClick={()=>modalRef.current.classList.add('active')}>
				Посмотреть выбранные источники
			</button>
		}
		{!fetching &&
			<PlatformsModalTable modalRef={modalRef} platforms={fetchedPlatforms} fromStream={true} />
		}
	</div>
}