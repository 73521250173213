import React, {useEffect, useRef} from "react";

function Modal({setOpened, opened, className, children}) {
	const modal = useRef();

	useEffect(() => {
		if (opened) {
			open_form();
		} else {
			close_form();
		}
	})

	function open_form() {
		modal.current.classList.add('active');
	}

	function close_form() {
		modal.current.classList.remove('active');
		setOpened(false)
	}

	return (
		<div ref={modal} className={"modal " + className}>
			<div className="modal-fade" onClick={(e) => {
				close_form()
			}}></div>
			<div className="modal-content">
				<div className="modal-close" onClick={(e) => {
					close_form()
				}}>
					<div className="cross"></div>
				</div>
				<div className="modal-body">
					{children}
				</div>
			</div>
		</div>
	)
}

export default Modal;