import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {APIClient} from "../../ApiEndpoints";
import {DOCUMENT_AND_STORIES_STAT} from "../../graphql/statisticQueries";
import Skeleton from "react-loading-skeleton";
import {useSearchParams} from "react-router-dom";
import SearchInput from "../../components/ui/SearchInput";
import {PLATFORM_PAGE} from "../../graphql/platformsQueries";
import {SavedPlatformSelect} from "../../components/platforms/SavedPlatformSelect";

const PlatformsCountriesFilter = React.lazy(() => import("../../components/platforms/PlatformsCountriesFilter"))
const PlatformTypeFilter = React.lazy(() => import("../../components/platforms/PlatformTypeFilter"))
const PlatformsPager = React.lazy(() => import("../../components/platforms/PlatformsPager"))
const PlatformsTable = React.lazy(() => import("../../components/platforms/PlatformsTable"))
const PlatformsSelection = React.lazy(() => import("../../components/platforms/PlatformsSelection"))
const PlatformsSaveButton = React.lazy(() => import("../../components/platforms/PlatformsSaveButton"))

const PlatformsPage = ({asSelector = false, sendSelectedPlatforms = undefined, sendAsList = undefined}) => {
	const [countriesSelected, setCountriesSelected] = useState([])
	const [typesSelected, setTypesSelected] = useState('')
	const [mainStat, setMainStat] = useState({docs: '', sources: ''})
	const [fetchStat, setFetchStat] = useState(false)
	const [fetchDocs, setFetchDocs] = useState(false)
	const [query, setQuery] = useSearchParams()
	const platformTypes = useSelector((state) => state.platform.platforms)
	const [documentsList, setDocementsList] = useState([])
	const [selectedPlatforms, setSelectedPlatforms] = useState({})
	const [searchString, setSearchString] = useState('')
	const [platformsCount, setPlatformsCount] = useState(0)
	const [pageSize, setPageSize] = useState(20)
	const [currentPage, setCurrentPage] = useState((query.get('page')) ?? 1)
	const [errors, setError] = useState({
		platforms: false
	})

	useEffect(() => {//query builder
		let qObj = {}
		if (countriesSelected)
			qObj.countries = countriesSelected.join('|')
		if (typesSelected)
			qObj.types = typesSelected
		if (searchString)
			qObj.q = searchString
		if (currentPage)
			qObj.page = currentPage

		setQuery(qObj)
	}, [typesSelected, countriesSelected, searchString, currentPage])


	useEffect(() => {
		setFetchStat(true)
		APIClient.query({
			query: DOCUMENT_AND_STORIES_STAT,
		})
			.then((res) => {
				if (res.data) {
					setMainStat({
						docs: res.data.paginationStory.showTotal,
						sources: res.data.paginationPlatform.total
					})
				}
			})
			.finally(() => {
				setFetchStat(false)
			})
	}, [])

	useEffect(() => {
		document.title = 'Мгимо аналитика: создание набора источников'
	}, [])

	useEffect(() => {//меняем платформы у родителя
		if(asSelector && typeof sendSelectedPlatforms === 'function')
			sendSelectedPlatforms(selectedPlatforms)
	}, [selectedPlatforms])

	const changePage = (number) => {
		if (number) {
			setCurrentPage(number)
			searchPlatforms(number)
		}
	}

	const getPlatforms = (variables) => {
		setFetchDocs(true)
		APIClient.query({
			query: PLATFORM_PAGE,
			variables: variables
		})
			.then((resp) => {
				if (resp.data && resp.data.paginationPlatform && resp.data.paginationPlatform.listPlatform)
					setDocementsList(resp.data.paginationPlatform.listPlatform)

				if (resp.data && resp.data.paginationPlatform && resp.data.paginationPlatform.total)
					setPlatformsCount(resp.data.paginationPlatform.total)

				setError({...errors, platforms: false})
				// console.log('DOCS_FETCH', resp)
			})
			.catch((resp) => {
				setError({...errors, platforms: true})
				console.error('DOCS_FETXH_FAIL', resp)
			})
			.finally(() => {
				setFetchDocs(false)
			})
	}

	useEffect(() => {//initial query parser
		const variables = {
			filterSettings: {},
			offset: (query.get('page')) ? (query.get('page') - 1) * pageSize : 0
		}

		if (query.get('countries')) {
			setCountriesSelected(query.get('countries').split('|'))
			if (query.get('countries').split('|').length > 0)
				variables.filterSettings.country = query.get('countries').split('|')
		}
		if (query.get('types')) {
			setTypesSelected(query.get('types'))
			variables.filterSettings.platformType = query.get('types')
		}
		if (query.get('page')) {
			setCurrentPage(parseInt(query.get('page')))
		}
		if (query.get('q')) {
			setSearchString(query.get('q'))
			variables.filterSettings.searchString = query.get('q')
			getPlatforms(variables);
		}
	}, []);

	const searchPlatforms = (page = 0, pSize = pageSize) => {
		const offset = (page === 0) ? (pageSize * (pSize - 1)) : (page - 1) * pSize
		const variables = {
			filterSettings: {},
			offset: offset,
			limit: pSize
		}

		if (countriesSelected.length > 0)
			variables.filterSettings.country = countriesSelected
		if (typesSelected)
			variables.filterSettings.platformType = typesSelected
		if (searchString)
			variables.filterSettings.searchString = searchString

		getPlatforms(variables)
	}

	const changePageSize = (size) => {//изменение размера страницы
		setPageSize(parseInt(size))
		console.log('pSize', parseInt(size))
		searchPlatforms(1, parseInt(size))
	}

	const setAllPlatforms = () => {//выбрать все возможные документы
		if(typeof  sendSelectedPlatforms === 'function')
			sendSelectedPlatforms("all")
	}

	const setPlatformsFromList = (list) => {
		if(typeof sendAsList === 'function')
			sendAsList(list)
	}

	return <>
		<div className={(!asSelector)? "main-content" : ''}>
			<div className={(!asSelector)? "content visible" : ''}>
				<div className="flex_row main-margin flex_row--sp_between">
					<h1 className="title-big c-main-dark">
						{asSelector
							? <>Выбор источников:</>
							: <>Список источников</>
						}
					</h1>

					<div className="flex_row">
						<div className="text--small text--light" style={{marginRight: '2.3rem'}}>
							Общее количество источников<br/>
							СМИ, соц. сети, блоги и др.
						</div>

						<div className={"title--middle"}>
							{fetchStat
								? <Skeleton width='100px' height="40px"/>
								: mainStat.sources
							}
						</div>
					</div>
				</div>

				{asSelector &&
					<>
						<div className='flex_row flex_row--sp_between flex_row--to_top'>
							<SavedPlatformSelect setPlatformList={setPlatformsFromList} setAll={setAllPlatforms} />
							<button className='btn small' onClick={() => {setAllPlatforms()}}>Выбрать все доступные источники</button>
						</div>
						<hr style={{margin: '3rem 0'}}/>
					</>
				}

				<PlatformsCountriesFilter selected={countriesSelected} setSelected={setCountriesSelected}/>

				<PlatformTypeFilter selected={typesSelected} setSelected={setTypesSelected}/>

				<SearchInput
					disabled={fetchDocs}
					query={searchString}
					setQuery={setSearchString}
					placeholder={'Введите название источника'}
					respAction={searchPlatforms}
					submit={'Искать'}
				/>

				{errors.platforms
					? <div className="error">Ошибка при получении списка источников</div>
					: <PlatformsTable
						total={platformsCount}
						selectedPlatforms={selectedPlatforms}
						selectPlatforms={setSelectedPlatforms}
						fetching={fetchDocs}
						platforms={documentsList}
						pageSize={pageSize}
						setPageSize={changePageSize}
					/>
				}

				{!fetchDocs &&
					<PlatformsPager set total={platformsCount} pageSize={pageSize} setCurrent={changePage}
					                current={currentPage}/>
				}

				{Object.keys(selectedPlatforms).length > 0 &&
					<>
						<PlatformsSelection fromStream={asSelector} platforms={selectedPlatforms} setPlatforms={setSelectedPlatforms}/>
						{!asSelector &&
							<PlatformsSaveButton platforms={selectedPlatforms}/>
						}
					</>
				}
			</div>
		</div>
	</>
}

export default PlatformsPage