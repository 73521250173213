import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import ListBlock from "../../components/ui/listBlock/ListBlock";
import "../../styles/pages/main.sass"

const MainAnalytics = React.lazy(() => import("../../components/mainPage/MainAnalytics"))

const Main = () => {
	useEffect(() => {
		document.title = 'Мгимо аналитика'
	}, [])
	return <main className='main-content content visible'>
		<MainAnalytics />

		<div className="main-analytic">
			<div className="ta-c title c-main-dark">Сформировать новую аналитическую работу:</div>

			<div className="grid main_buttons">

				<div className="col">
					<Link to="/concept" className="btn">Сформировать досье</Link>
					<div className="main-analytic-desc">
						Получить сведения об объекте, занесенные в базу знаний, включая связи с другими объектами, статистику упоминаний в источниках.
					</div>
				</div>


				<div className="col">
					<Link to="/connections" className="btn">Построить связи объектов</Link>
					<div className="main-analytic-desc">
						Получить сеть прямых и опосредованных связей одного, двух или более объектов, занесенных в базу знаний, статистику упоминаний связанных объектов.
					</div>
				</div>


				<div className="col">
					<Link to="/stream" className="btn">Сформировать обновляемую ленту</Link>
					<div className="main-analytic-desc">
						Настроить и сохранить автоматически обновляемую ленту новостей из заданных источников по заданной в поисковом запросе теме.
					</div>
				</div>


				<div className="col">
					<Link to="/stream/select" className="btn">Сформировать фиксированную ленту</Link>
					<div className="main-analytic-desc">
						Лента материалов с заданным временным интервалом. Данный тип ленты является не обновляемым в автоматическом режиме.
					</div>
				</div>

			</div>
		</div>

		<div className="list-block-wrap">
			<ListBlock
				title="Сформированные отчеты"
				link="/reports"
				link_name="Посмотреть все отчеты"
				type="files"
				limit={5}
			/>
			<ListBlock
				title="Журнал действий"
				link="/history"
				limit={5}
				link_name="Посмотреть все действия"
				type="journal"
			/>
		</div>
	</main>
}

export default Main