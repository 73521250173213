import possibleTypes from './graphql/possibleTypes.json'

// export const TALISMAN_HOST = 'https://mediadata.talisman.ispras.ru'
export const TALISMAN_HOST = 'https://mgimo.talisman.ispras.ru'
// export const TALISMAN_HOST = 'https://demo.talisman.ispras.ru'

let backHost = ''
let frontHost = ''
if(process.env.NODE_ENV === 'development'){//todo: поменять в проде
	backHost = 'http://localhost'
	frontHost = 'http://localhost:3000'
}else if(process.env.NODE_ENV === 'production'){
	// backHost = 'https://isp.sawdev.ru'
	// frontHost = 'https://isp.sawdev.ru'
	backHost = 'https://talismanmgimo-dev.ispras.ru'
	frontHost = 'https://talismanmgimo-dev.ispras.ru'
}

export const BACK_HOST = backHost
export const FRONT_HOST = frontHost

export const POSSIBLE_TYPES = possibleTypes;

export const CALENDAR_LOCALE = {
	// months list by order
	months: [
		'Январь',
		'Февраль',
		'Март',
		'Апрель',
		'Май',
		'Июнь',
		'Июль',
		'Август',
		'Сентябрь',
		'Октябрь',
		'Ноябрь',
		'Декабрь',
	],

	// week days by order
	weekDays: [

		{
			name: 'Понедельник',
			short: 'Пн',
		},
		{
			name: 'Вторник',
			short: 'Вт',
		},
		{
			name: 'Среда',
			short: 'Ср',
		},
		{
			name: 'Чертвер',
			short: 'Чт',
		},
		{
			name: 'Пятница',
			short: 'Пт',
		},
		{
			name: 'Суббота',
			short: 'СБ',
			isWeekend: true,
		}, {
			name: 'Воскресенье', // used for accessibility
			short: 'Вс', // displayed at the top of days' rows
			isWeekend: true, // is it a formal weekend or not?
		},
	],

	// just play around with this number between 0 and 6
	weekStartingIndex: 6,

	// return a { year: number, month: number, day: number } object
	getToday(gregorainTodayObject) {
		return gregorainTodayObject;
	},

	// return a native JavaScript date here
	toNativeDate(date) {
		return new Date(date.year, date.month - 1, date.day);
	},

	// return a number for date's month length
	getMonthLength(date) {
		return new Date(date.year, date.month, 0).getDate();
	},

	// return a transformed digit to your locale
	transformDigit(digit) {
		return digit;
	},


	// for input range value
	from: 'От',
	to: 'До',


	// used for input value when multi dates are selected
	digitSeparator: ',',

	// if your provide -2 for example, year will be 2 digited
	yearLetterSkip: 0,

	// is your language rtl or ltr?
	isRtl: false,
}