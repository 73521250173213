import React from "react";
import PlatformTableList from "./PlatformTableList";
import PlatformsSaveButton from "./PlatformsSaveButton";

export default function PlatformsModalTable({modalRef, platforms, selectedPlatforms, fromStream = false, setPlatforms}) {

	return <div className="modal_holder" ref={modalRef}>
		<div className="modal_bg" onClick={()=>modalRef.current.classList.remove('active')}></div>
		<div className="modal_content modal_content__wide content">
			<div className='modal_close' onClick={()=>modalRef.current.classList.remove('active')}>
				{!fromStream &&
					<p onClick={()=>modalRef.current.classList.toggle('active')}>Вернуться к списку</p>
				}
				<div className="cross" onClick={()=>modalRef.current.classList.remove('active')}></div>
			</div>

			<div className="modal_table">
				<PlatformTableList platforms={platforms} selectedPlatforms={selectedPlatforms} setSelectedPlatforms={setPlatforms} saved={fromStream} />

				{!fromStream &&
					<>
						<div>&nbsp;</div>
						<PlatformsSaveButton platforms={platforms} />
						<div>&nbsp;</div>
						<div>&nbsp;</div>
						<div>&nbsp;</div>
					</>
				}
			</div>
		</div>
	</div>
}